import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import Image from "next/image";
import Link from "next/link";
import moment from 'moment';
import TippyIcon from '../TippyIcon';
import LinkedIn from "../../../public/img/event_speaker_section/LinkedIn.svg";
import facebook from "../../../public/img/event_speaker_section/facebook.svg";
import twitter from "../../../public/img/event_speaker_section/twitter-logo-white.svg";
import website from "../../../public/img/event_speaker_section/website.svg";

const WorkshopDetails = ({
    isOpen,
    closeModal,
    workshopDetails,
    dateForModal,
    convertToHTML,
    eventUrl
}) => {
    const timeDiff = () => {
        const startTime = moment(workshopDetails.start_timestamp);
        const endTime = moment(workshopDetails.end_timestamp);
        const duration = moment.duration(endTime.diff(startTime));
        const hours = duration.asHours();
        const minutes = duration.asMinutes();

        if (minutes <= 60) {
            return `${minutes} Mins`;
        }
        if (minutes > 60) {
            return `${hours} Hours`;
        }
    };

    const [modalWidth, setModalWidth] = useState('80%');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalWidth('95%'); // For mobile screens
            } else {
                setModalWidth('80%'); // For larger screens
            }
        };

        // Set initial width
        handleResize();

        // Add event listener on resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '94%',
            width: modalWidth, // Dynamic width based on screen size
            maxHeight: 'calc(100% - 75px)',
            overflow: 'auto'
        },
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => closeModal()}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style={customStyles}
            >
                <div className="row p-0 p-sm-3">
                    <div>
                        <i
                            className="fas fa-times closeIcon d-flex justify-content-end"
                            onClick={() => closeModal()}>
                        </i>
                    </div>
                    <div className="col-12 workshop-modal-title-section">
                        <div className="d-flex justify-content-between">
                            <span className="workshop-modal-title">
                                {workshopDetails.workshop_title}
                            </span>

                        </div>
                    </div>
                    <div className="col-12 workshop-modal-date-section">
                        <span className="workshop-modal-date">{dateForModal(workshopDetails)}</span>
                        <span className="workshop-modal-date date-diff">
                            <img className="time-img" src="/img/time1.svg" alt="time" />
                            {timeDiff(workshopDetails)}
                        </span>
                    </div>
                    {workshopDetails.is_virtual === true ? (
                        <div className='col-12 workshop-modal-location-section'>
                            {workshopDetails.url && (
                                <img className="online-img" src="/Event/icons/online.png" alt="location" />
                            )}
                            <div className="workshop-modal-location">
                                <a href={workshopDetails.url} target="_blank">
                                    {workshopDetails.url}
                                </a>
                            </div>
                        </div>

                    ) : (
                        <div className='col-12 workshop-modal-location-section'>
                            {workshopDetails.venue && (
                                <img className="location-img" src="/img/location_two_icon.svg" alt="location" />
                            )}
                            <div className="workshop-modal-location">
                                {workshopDetails.venue}
                            </div>
                        </div>
                    )}


                    <div className="col-12 workshop-modal-description-section mb-4">
                        <p className="workshop-modal-description" dangerouslySetInnerHTML={convertToHTML(workshopDetails)} />
                    </div>
                    <div className="container workshop-modal-speaker-section mb-2">
                        {workshopDetails?.workshop_speakers?.length > 0 && (
                            <div className="row">
                                {workshopDetails?.workshop_speakers?.map((speaker, index) => (
                                    <div key={index} className="col-12 col-sm-6 mb-4 d-flex flex-column align-items-center">
                                        <div className="d-flex desc-cont gap-sm-2  flex-row flex-md-column align-items-center speaker-card">
                                            <div className="organiser-image-cont-2">
                                                <img
                                                    src={speaker?.image_url}
                                                    alt={`${speaker?.name} poster image`}
                                                    className="speaker-image img-fluid"
                                                />
                                            </div>
                                            <div lang="de" className=" desc-cont col-md-7 speaker-hypen">
                                                <div className='speaker-info' >
                                                    <p className="name mt-0 mb-1" >{speaker?.name}</p>
                                                    <p className="desc2 mt-0 mb-1">{speaker?.designation}</p>
                                                    <p className="org mt-0 mb-1">{speaker?.organisation}</p>
                                                </div>
                                                {(speaker?.linkedin_url ||
                                                    speaker?.facebook_url ||
                                                    speaker?.twitter_url ||
                                                    speaker?.website_url) && (
                                                        <div className="d-flex desc-cont  flex-row mt-3 justify-content-start">
                                                            {speaker?.facebook_url && (

                                                                <div className="facebook_wrapper twitter_wrapper" style={{ marginRight: 12 }}>
                                                                    <a href={speaker.facebook_url} target={"_blank"}>
                                                                        <img className='social-icons' src={facebook} alt={"facebook"} />
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {speaker?.twitter_url && (
                                                                <div className="twitter_wrapper" style={{ marginRight: 12 }}>
                                                                    <a href={speaker.twitter_url} target={"_blank"}>
                                                                        <img className='social-icons' src={twitter} alt={"twitter"} />
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {speaker?.linkedin_url && (

                                                                <div className="twitter_wrapper" style={{ marginRight: 12 }}>
                                                                    <a href={speaker.linkedin_url} target={"_blank"}>
                                                                        <img className='social-icons' src={LinkedIn} alt={"Linked In"} />
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {speaker?.website_url && (
                                                                <div className="twitter_wrapper">
                                                                    <a href={speaker.website_url} target={"_blank"}>
                                                                        <img className='social-icons' src={website} alt={"website"} />

                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>

                    {workshopDetails.workshop_tickets[0] && (
                        <Link
                            href={{
                                pathname: `/checkout/${eventUrl}`,
                                query: {
                                    ticketId: `${workshopDetails.workshop_tickets[0].ticket_id}`,
                                },
                            }}
                        >
                            <div className="col-12 register-btn rounded mt-4">
                                <span className="site-link-text text-white text-center text-decoration-none buy-btn d-flex align-items-center justify-content-around rounded py-2 px-2">Register</span>
                            </div>
                        </Link>)}
                </div>
            </Modal>
            <style jsx>{`
                .closeIcon {
                    font-size: 1.5rem;
                    color: black;
                    opacity: 0.5;
                    cursor: pointer;
                }
                .workshop-modal-title{
                    font-family: 'Prompt';
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    color: #000;
                }
                .buy-btn{
                    max-width:220px;
                    text-align:center;
                }

                .workshop-modal-date-section{
                    margin-top: 30px;
                }
                .workshop-modal-date{
                    font-family: 'Prompt';
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #FDB14B;
                }
                .date-diff{
                    margin-left: 20px;
                    background:  #FFF1F0;
                    border-radius: 5px;
                    padding: 6px 13px;
                    line-height: 36px;
                    background-color:#FFEFDA;
                }
                .time-img{
                    margin-right: 11px;
                    margin-top: -2px;
                }
                .social-icons{
                    width:20px;
                    height:20px;
                    margin-bottom: 3px;
                }

                .workshop-modal-location-section{
                    margin-top: 27px;
                    display: flex;
                    // align-items: center;
                }
                .workshop-modal-location{
                    margin-left: 12px;
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #000000;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    word-break: break-all;
                    display: block;
                    max-width: 100%; /* Ensure the link doesn't exceed its container */
                }
                .twitter-speaker  .facebook_wrapper, .twitter_wrapper {
                        filter: invert(100%);
                        height: 20px;
                        width: 20px;
                 }
                .workshop-modal-location a {
                    text-decoration: none;
                }
                .speaker-info {
                    font-family: 'Prompt';
                }
                .name{
                    font-family: 'Prompt';
                }

                .workshop-modal-description-section{
                    margin-top: 25px;
                }
                .workshop-modal-description{
                    font-family: 'Prompt';
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                }
                .speaker-title{
                    margin-top: 15px;
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #FB5850;
                }
                .linkedIn, .facebook, .twitter, .website {
                        width: 19px;
                        height:19px;
                        //margin-top: -19px;
                        filter: invert(100%);
                    }

                .desc-cont{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }
                @media (max-width: 709px) {
                    .workshop-modal-date-section{
                        display:flex;
                       // flex-direction:column;
                        gap:13px;
                        align-items : center;
                        align-items: center;
                }
                .speaker-info .name{
                        font-weight: 700;
                        font-family: 'Prompt';
                }
                .speaker-info .desc2{
                        //font-family: 'Prompt';
                }
                }
                @media (max-width: 576px) {
                    .workshop-modal-date-section{
                        margin-top: 17px;
                        display:flex;
                        flex-direction:column;
                        gap:5px;
                       align-items: center;

                    }
                    .date-diff{
                        margin-left: 0px;
                        width:fit-content;
                    }
                    .workshop-modal-description-section{
                        margin-top: 2px;
                    }
                }
                 @media (max-width: 576px) {
                    .workshop-modal-title{
                        font-weight: 500;
                        font-size: 18px;
                        font-family: Prompt;
                      }

                    .workshop-modal-date-section {
                        margin-top: 15px;
                    }
                    .workshop-modal-date {
                        font-weight: 500;
                        font-size: 14px;
                        letter-spacing: 0px;
                        line-height: 24px;
                    }
                    .date-diff{
                        padding: 6px 10px ;
                    }
                    .width-full {
                        //width:94% !important;
                    }
                 }
                .speaker-card {
                    width: 100%;
                    border: 1px solid #00000033;
                    border-radius:5px;
                    padding: 10px;
                }
                .organiser-image-cont-2 img {
                    border: 1px solid #00000033;
                    border-radius: 0;
                }
                @media (min-width: 768px) {
                    .speaker-card {
                        flex-direction: column;
                        align-items: center;
                    }
                    .organiser-image-cont-2 img {
                        width: 130px;
                        height: auto;
                    }
                }
                @media (max-width: 767px) {
                    .speaker-card {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }
                    .organiser-image-cont-2 img {
                        width: 90px;
                        height: auto;
                        margin-right: 10px;
                    }
                    .speaker-info .name{
                        font-weight: 800;
                    }
                }
                    @media (min-width: 992px) {
                        .col-lg-3 {
                            max-width: 130px;
                        }
                        .organiser-image-cont-2 img {
                            width: 130px;
                            height: auto;
                        }
                    }
                    .speaker-card {
                        // cursor: pointer;
                        background: #ffffff;
                        border-radius: 5px;
                        border: 0.8px solid #cacaca;
                        text-align: left;
                        z-index: 100;
                    }
                    .speaker-box {
                        gap: 20px;
                    }
                    .speaker-hypen {
                        font-size: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .icon {
                        max-width: 17px;
                        margin-top: -25px;
                        cursor: pointer;
                        -webkit-filter: invert(100%);
                        filter: invert(100%);
                    }
                    .speaker-image {
                        cursor: pointer;
                        border-radius: 5px;
                        object-fit: cover;
                        width: 100%;
                    }
                    .name {
                        cursor: pointer;
                        font-weight: 700;
                        margin-top: 5px;
                        font-size: 1rem;
                        color: #333333;
                        font-family:'Prompt';
                    }
                    .desc2 {
                        cursor: pointer;
                        color: #333333;
                        font-family:'Hind';
                        font-size:15px;
                    }
                    
                    .facebook_wrapper, .twitter_wrapper {
                        margin-left: 0px;
                    }
                    .twitter-speaker {
                        width: 24px;
                        height: 24px;
                    }
                    .desc {
                        font-family: "Manrope" !important;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: #000000;
                        overflow-wrap: break-word;
                    }
                    .desc-cont{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }
                    .org {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 0.875rem;
                        color: #141b25;
                    }
                    .speaker-info name{
                        font-size: 17px;
                        font-weight: 700 !important;
                    }
                    @media (max-width: 576px) {
                        .name {
                            font-size:16px;
                            font-weight: 500;
                        }
                        .desc2 {
                            font-size:14px;
                            font-weight: 400;
                        }
                        .desc-cont{
                            display: flex;
                            flex-direction: column;
                            justify-content: start;
                            align-items: start;
                            text-align: start;
                        }
                        .twitter_wrapper {
                            margin-left: 0 !important;
                        }
                        .workshop-modal-title{
                            font-weight: 500;
                            font-size: 18px;
                        }
                        .workshop-modal-date{
                            font-size:14px;
                            font-weight: 400;
                        }
                        .time-img {
                            margin-right: 8px !important;
                            margin-top: -2px !important;
                            width: 15px !important;
                            height: 15px !important;
                        }
                        .workshop-modal-date{
                            padding:0px 6px;
                            letter-spacing: 0;
                        }
                        .workshop-modal-date-section{
                            flex-direction: row !important;
                            align-items: center;
                            flex-wrap: wrap;
                        }
                        .workshop-modal-description-sectio{
                            margin-top: 5px;
                        }
                        .speaker-info .name{
                            font-size: 16px;
                            font-weight: 500 !important;
                        }
                    }
    
                        font-size: 11px;
                    }
                    .workshop-modal-date{
                        font-size: 11px;
                    }
                    .speaker-title{
                        font-size:16px;
                    }
                }
            .speaker-card {
                width: 100%;
                border: 1px solid #00000033;
                border-radius:5px;
                padding: 10px;
            }

            .organiser-image-cont-2 img {
                border: 1px solid #00000033; 
                border-radius: 0; 
            }
            .linkedIn,
				.facebook,
				.twitter,
				.website {
					width: 19px;
                    height:19px;
					//margin-top: -19px;
                    filter: invert(100%);
                    margin-bottom: 3px;
				}
            @media (min-width: 768px) {
            .speaker-card {
                flex-direction: column;
                align-items: center;
            }
            .organiser-image-cont-2 img {
                width: 130px;
                height: auto;
            }
        }

            @media (max-width: 767px) {
            .speaker-card {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .organiser-image-cont-2 img {
                width: 90px;
                height: auto;
                margin-right: 10px;
            }
          

            @media (min-width: 992px) {
                .col-lg-3 {
                    max-width: 130px;
                }
                .organiser-image-cont-2 img {
                    width: 130px;
                    height: auto;
                }
            }
            	.speaker-card {
					// cursor: pointer;
					background: #ffffff;
					border-radius: 5px;
					border: 0.8px solid #cacaca;
					text-align: left;
					z-index: 100;
				}
				.speaker-box {
					gap: 20px;
				}
				
				.speaker-hypen {
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
				}
				.icon {
					max-width: 17px;
					margin-top: -25px;
					cursor: pointer;
					-webkit-filter: invert(100%); 
					filter: invert(100%);
				}
				.speaker-image {
					cursor: pointer;
					border-radius: 5px;
					object-fit: cover;
					width: 100%;
				}
				.name {
					cursor: pointer;
					font-weight: 800;
					margin-top: 5px;
					font-size: 1rem;
					color: #333333;
                    font-family:'Hind';
				}
				.desc2 {
					cursor: pointer;
                    color: #333333;
                    font-family:'Hind';
                    font-size:15px;
				}
				.linkedIn,
				.facebook,
				.twitter,
				.website {
					width: 19px;
                    height:19px;
					//margin-top: -19px;
                    filter: invert(100%);
                    margin-bottom: 3px;
				}
                .facebook_wrapper, .twitter_wrapper {
                     margin-left: 0px; 
                 }
				.twitter-speaker {
					width: 24px;
					height: 24px;
				}
				.desc {
					font-family: "Manrope" !important;
					font-weight: 400;
					font-size: 13px;
					line-height: 20px;
					color: #000000;
					overflow-wrap: break-word;
				}
                .desc-cont{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
				.org {
					cursor: pointer;
					font-style: normal;
					font-weight: 500;
					font-size: 0.875rem;
					color: #141b25;
				}
                .speaker-info name{
                    font-size: 17px;
                    font-weight: 700 !important;
                }
                @media (max-width: 576px) {
                    .name {
                        font-size:16px;
                        font-weight: 500;
                    }
                    .desc2 {
                        font-size:14px;
                        font-weight: 400;
                    }
                    .desc-cont{
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        align-items: start;
                        text-align: start;
                    }
                    .twitter_wrapper {
                        margin-left: 0 !important; 
                    }
                    .workshop-modal-title{
                        font-weight: 500;
                        font-size: 16px;
                    }
                    .workshop-modal-date{
                        font-size:14px;
                        font-weight: 500;
                    }
                    .time-img {
                        margin-right: 8px !important;
                        margin-top: -2px !important;
                        width: 15px !important;
                        height: 15px !important;
                    }
                    .workshop-modal-date{
                        padding:0px 6px;
                        letter-spacing: 0;
                           
                    }
                    .workshop-modal-date-section{
                        flex-direction: row !important;
                        align-items: center;
                        flex-wrap: wrap;
                    }
                    .workshop-modal-description-section{
                        margin-top: 5px;
                    }
                    .ReactModal__Content {
                        width: 96% !important; /* Ensure the modal takes up 96% of the screen width */
                        left: 50% !important; /* Re-center the modal */
                        transform: translate(-50%, -50%) !important;
                    }
                    .workshop-modal-title{
                        font-weight: 500;
                        font-size: 18px;
                    }
                    .speaker-info .name{
                        font-weight: 400;
                    }
                }

            `}</style>
        </>
    )
}

export default WorkshopDetails;
