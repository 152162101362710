import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { addAttributesToLinks } from "../helpers/utils";

/**
 *
 * @param {{ content: HTMLElement | string ,lines:number,showLess:string,showMore:string,buttonTextStyle:React.CSSProperties,buttonTextClassName:string,forcedHeight:string}} props
 * @returns
 */
export default function ReadMore(props) {
	const showMore = props.showMore || "More",
		showLess = props.showLess || "Less",
		content = props.content?.replace(/(?:\r\n|\r|\n)/g, "") || "",
		buttonTextStyle = props.buttonTextStyle,
		buttonTextClassName = props.buttonTextClassName || "",
		forcedHeight = props.forcedHeight,
		color = props.color,
		onClick = props.onClick ? props.onClick : false,
		//Styles Acceps fontFamily, fontSize, fontWeight
		styles =
			props?.styles && Object.keys(props?.styles).length
				? `style="${props?.styles?.fontFamily ? `font-family: ${props?.styles?.fontFamily}; ` : ""} ${props?.styles?.fontSize ? `font-size: ${props?.styles?.fontSize}; ` : ""} ${props?.styles?.fontWeight ? `font-weight: ${props?.styles?.fontWeight}; ` : ""} ${props?.styles?.margin ? `margin: ${props?.styles?.margin}; ` : ""}"`
				: 'style="font-family: Hind, sans-serif; "',
		maxChar = props.maxChar;
	const [expanded, setExpanded] = useState(props.expanded || false);
	const [htmlCont, setHtmlCont] = useState(props.expanded || false);

	function charCount(str) {
		return str.length;
	}

	function showNoOfChars(str, no_of_chars) {
		return str.substring(0, maxChar == "auto" ? str.length : no_of_chars);
	}

	useEffect(() => {
		if (content && maxChar) {
			let htmlObject = document.createElement("div");
			const InnterHtmlContent = `
			${charCount(content) > maxChar && !expanded // if content is higher than limit and not expanded
					? `${showNoOfChars(replaceAll(content, 'style="margin: 0px; font-family: Hind, sans-serif;"', ""), maxChar)}`
					: content
				}
			`;
			let finalText = "";
			htmlObject.innerHTML = InnterHtmlContent;
			let isHtml = /<\/?[a-z][\s\S]*>/i.test(InnterHtmlContent);
			if (charCount(content) > maxChar && isHtml) {
				htmlObject.lastElementChild.innerHTML += `${!expanded ? "..." : ""} <a class="${buttonTextClassName}" style="color: ${color ? color : "#fb5850"}; cursor: pointer; ${buttonTextStyle}" > ${expanded ? showLess : showMore}</a>`;
				let elems = htmlObject.querySelectorAll("p");

				for (var i = 0; i < elems.length; i++) {
					//Add margin 0px to all the p tags to remove extra space
					elems[i].style.margin = "0px";
				}
				finalText = htmlObject.innerHTML;
			} else if (charCount(content) > maxChar && !isHtml) {
				finalText =
					InnterHtmlContent +
					`${!expanded ? "..." : ""} <a class="${buttonTextClassName}" style="color: ${color ? color : "#fb5850"}; cursor: pointer; ${buttonTextStyle}" >${expanded ? showLess : showMore}</a>`;
			} else {
				finalText = content;
			}
			const _finalText = addAttributesToLinks(finalText);
			setHtmlCont(_finalText);
		}
	}, [content, maxChar, expanded, buttonTextClassName, buttonTextStyle]);

	function replaceAll(string, search, replace) {
		return string.split(search).join(replace);
	}

	// const InnterHtmlContent = `
	// 		${
	// 			charCount(content) > maxChar && !expanded
	// 				?
	// 			`${showNoOfChars(replaceAll(content, 'style="margin: 0px; font-family: Hind, sans-serif;"', ''), maxChar)}...` + " " +
	// 			`<a class="${buttonTextClassName}" style="color: ${color ? color : "#fb5850"}; cursor: pointer; ${buttonTextStyle}" > ${expanded ? showLess : showMore}</a>`
	// 				:
	// 			charCount(content) > maxChar && expanded
	// 				?
	// 			`${content.replace(/<\/\S+>$/, "")} <a "${buttonTextClassName}"
	// 				style="color: ${color ? color : "#fb5850"}; cursor: pointer; ${buttonTextStyle}"
	// 			>
	// 				${expanded ? showLess : showMore}
	// 			</a>`
	// 				:
	// 			content
	// 		}
	// `

	return (
		<>
			{htmlCont && <div>
				<div
					className={`line-clamp `}
					style={{
						...props?.styles?.fontFamily && { fontFamily: props.styles.fontFamily },
						...props?.styles?.fontSize && { fontSize: props.styles.fontSize },
						...props?.styles?.fontWeight && { fontWeight: props.styles.fontWeight }
					}}
					dangerouslySetInnerHTML={{
						// __html: content.replace(/\n/g, "<br />").replace("Terms & Conditions", `<a target="_blank" rel="noopener,noreferrer" href="https://konfhub.com/terms-and-conditions">Terms & Conditions</a>`)
						// .replace("Code Of Conduct", `<a href="https://konfhub.com/code-of-conduct" target="_blank" rel="noopener,noreferrer">Code Of Conduct</a>`)
						// .replace("Privacy Policy", `<a href="https://konfhub.com/privacy-policy" target="_blank" rel="noopener,noreferrer">Privacy Policy</a>`)
						__html: htmlCont.replace("Terms & Conditions", `<a target="_blank" rel="noopener,noreferrer" href="https://konfhub.com/terms-and-conditions">Terms & Conditions</a>`)
							.replace("Code Of Conduct", `<a href="https://konfhub.com/code-of-conduct" target="_blank" rel="noopener,noreferrer">Code Of Conduct</a>`)
							.replace("Privacy Policy", `<a href="https://konfhub.com/privacy-policy" target="_blank" rel="noopener,noreferrer">Privacy Policy</a>`)
							.replaceAll('style="font-family: Hind, sans-serif; margin: 0px;"', styles)
							.replaceAll('style="font-family: Hind, sans-serif;"', styles)
							.replaceAll('style="margin: 0px; font-family: Hind, sans-serif;"', styles)
							.replaceAll('<li', '<li class="text-start"')
							.replaceAll('<p', '<p class="mb-1  fs-6-sm"')
							.replaceAll('<table', '<table style="border: 1px solid black; text-align: center;"')
							.replaceAll('<td', '<td style="border: 1px solid black; text-align: center;"')
							.replaceAll('<th', '<th style="border: 1px solid black; text-align: center;"')
					}}
					onClick={(e) => {
						const el = e.target.closest("a"); //The closest ancestor Element or itself, which matches the selectors. If there are no such element, null.
						if (el && e.currentTarget.contains(el) && !onClick) {
							setExpanded(!expanded);
						} else if (onClick) {
							onClick();
						}
					}}
				/>
			</div>}
			<style jsx>{`
			.line-clamp ol, .line-clamp ul {
				text-align: left;
			}
			.line-clamp
			{
				display            : block;
				display            : -webkit-box;
				-webkit-box-orient : vertical;
				position           : relative;
				line-height        : 1.7;
				overflow           : hidden;
				text-overflow      : ellipsis;
				word-break: break-word;
				
			}
			p {
				margin-bottom: 0px !important;
			}

			.line-clamp p {
				font-size:16px !important;
			}
			@media only screen and (max-width: 576px) {

			.line-clamp {
				line-height: normal !important;
				//font-weight: 500;
		}

			}
			 .line-clamp p {
				font-size: 14px !important;
				text-align: center !important;
      		  }
				p {
					font-size: 14px !important;
				}
			
		}



		`}</style>
		</>
	);
}
