import React, { Fragment, useContext, useState } from "react";
import moment from "moment";
import { convertTimezone } from "../helpers/common";
import eventContext from "../contexts/EventContext";
import WorkshopDetails from "./Modal/WorkshopDetails";
import TippyIcon from "./TippyIcon";
import Image from "next/image";

const WorkshopCard = ({ workshopDetails, eventUrl, color = null }) => {
    const [openModal, setOpenModal] = useState(false)
    const { eventData } = useContext(eventContext)
    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }
    const getTimeZone = () => {
        if (date.timezone.includes("+")) {
            return `GMT${date.timezone}`
        } else {
            return date.timezone;
        }
    }

    const localTimeZone = moment.tz.guess();
    let workshop_start_time = workshopDetails.start_timestamp;
    let workshop_end_time = workshopDetails.end_timestamp;

    // Convert the workshop start and end times to the user's local timezone
    const date = {
        start_month: moment.tz(workshop_start_time, eventData.time_zone).tz(localTimeZone).format("MMM Do"),
        start_year: moment.tz(workshop_start_time, eventData.time_zone).tz(localTimeZone).format("YYYY"),
        start_time: moment.tz(workshop_start_time, eventData.time_zone).tz(localTimeZone).format("h:mm A"),
        timezone: moment.tz(workshop_start_time, localTimeZone).format("z"),
    };

    const isSameDate = (date) => {
        return `${date.start_month}, ${date.start_year} at ${date.start_time} (${date.timezone})`;
    };

    const dateForModal = (workshopDetails) => {
        let start_time = moment.tz(workshopDetails.start_timestamp, eventData.time_zone).tz(localTimeZone).format("h:mm A");
        let end_time = moment.tz(workshopDetails.end_timestamp, eventData.time_zone).tz(localTimeZone).format("h:mm A");
        let timezone = moment.tz(workshopDetails.start_timestamp, localTimeZone).format("z");

        return `${start_time} - ${end_time} ${timezone}`;
    };

    const dateWoTime = (workshopDetails) => {
        let start_time = moment.tz(workshopDetails.start_timestamp, eventData.time_zone).tz(localTimeZone).format("h:mm A");
        let end_time = moment.tz(workshopDetails.end_timestamp, eventData.time_zone).tz(localTimeZone).format("h:mm A");
        let timezone = moment.tz(workshopDetails.start_timestamp, eventData.time_zone).tz(localTimeZone).format("z");

        return `${start_time} - ${end_time} ${timezone}`;
    };

    function convertToHTML(workshopDetails) {
        return { __html: `${workshopDetails?.workshop_description?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` };
    }

    return (
        <Fragment>
            <div className="workshop-card">
                {workshopDetails.workshop_banner_url && <div
                >
                    <Image fill className="workhop-image position-relative" src={workshopDetails.workshop_banner_url} alt="Banner"
                        style={{
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            objectFit: "cover",
                            border: "1px solid #cacaca"
                        }} />
                </div>}
                <div className="row workshop-details-section">
                    <div className="col-12 workshop-title-section">
                        <span className="workshop-title">
                            {workshopDetails.workshop_title}
                        </span>
                    </div>

                    <div className="col-12 workshop-date-section">
                        <span className="workshop-date">{isSameDate(date)}</span>
                    </div>
                    {
                        workshopDetails.workshop_description &&
                        <div className={`${workshopDetails.workshop_banner_url ? "workshop-description-section" : "workshop-description-section-noImage"} col-12`}>
                            <p className="workshop-description" dangerouslySetInnerHTML={convertToHTML(workshopDetails)} />
                        </div>
                    }
                    {
                        <div className="workshop-speaker-section">
                            {workshopDetails.workshop_speakers && workshopDetails.workshop_speakers.map((speaker, index) => (
                                <TippyIcon
                                    content={speaker.name}
                                    placement='top'
                                >
                                    <img className="workshp-speaker-image position-relative rounded-full" src={speaker.image_url} alt="Speaker"
                                        width={60}
                                        height={60}
                                        style={{
                                            borderRadius: '50%',
                                            marginRight: '9px'
                                        }}
                                    />
                                </TippyIcon>

                            ))}
                        </div>
                    }

                    <div className="col-12 view-btn rounded mt-3">
                        <span onClick={() => setOpenModal(true)} className="site-link-text text-white text-center text-decoration-none buy-btn d-flex align-items-center justify-content-around rounded py-2 px-2">
                            View Details
                        </span>
                    </div>
                </div>
                {openModal &&
                    <WorkshopDetails
                        workshopDetails={workshopDetails}
                        dateForModal={dateForModal}
                        date={dateWoTime}
                        convertToHTML={convertToHTML}
                        eventUrl={eventUrl}
                        isOpen={openModal}
                        closeModal={() => setOpenModal(false)}
                    />
                }
            </div>
            <style jsx>{`
                .workshop-card {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    padding:12px 12px;
                    position: relative;
                }

                .workshop-details-section{
                    padding: 0 25px;
                }

                .workshop-title-section {
                    height: 70px;
                    margin-top: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-title{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 35px;
                    color: #161616;
                }

                .workshop-date-section{
                    margin-top: 15px;
                }
                .workshop-date{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: ${color ? color : "#FB5850"};
                }

                .workshop-description-section{
                    height: 62px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-description-section-noImage{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 14; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .workshop-description{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: #101010;
                    margin-top: 15px;
                }

                .workshop-speaker-section{
                    display: flex;
                    margin-top: 20px;
                    height: 55px;
                }

                .view-btn{
                    margin: 28px 0;
                    // position: absolute;
                    // bottom: 20px;
                    // right: -17px;
                    display: flex;
                }
                .view-btn *{
                    font-family: 'Prompt';
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                @media (max-width: 1067px) {
                    .workshop-title-section {
                        height: auto;
                    }

                    .workshop-description-section{
                        height: auto;
                    }

                    .workshop-speaker-section{
                        height: auto;
                    }
                }
              @media (max-width: 576px) {
                  .workshop-details-section {
                      padding: 0 5px;
                  }
                  .workshop-title {
                      font-size: 16px;
                      font-weight: 500;
                      font-family: 'Prompt';
                  }
                  .workshop-date {
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: 0px;
                  }
                  .workshop-date-section {
                      margin-top: 5px;
                  }
                  .workshop-description {
                      margin-top: 12px;
                      font-weight: 400;
                      font-size: 14px;
                  }
                  .workshop-speaker-section {
                      margin-top: 12px;
                  }
                  .workshop-card {
                      padding: 15px 15px;
                  }
                  .view-btn {
                      font-size: 16px !important;
                      padding: 8px 12px !important;
                      width: 100%;
                      margin: 5px 0px 0;
                      min-width: 145px;
                      white-space: nowrap;
                  }
                  .view-btn span {
                      font-size: 16px !important;
                      font-weight: 400;
                      padding: 8px 25px !important;
                      line-height: 24.20px;
                  }
              }

              @media (max-width: 386px) {
                  .workshop-date {
                      font-size: 14px;
                      letter-spacing: 0px;
                  }
                  .workshop-description {
                      font-size: 14px;
                      font-weight: 400;
                  }
                  .view-btn {
                      white-space: nowrap;
                  }
              }

              @media (max-width: 336px) {
                  .workshop-date {
                      font-size: 14px;
                      letter-spacing: 0px;
                  }
                  .workshop-description {
                      font-size: 15px;
                  }
              }

                    }
                }
                 @media (max-width: 376px) {
                    .view-btn{
                        width:fit-content;
                    }
                     .workshop-date{
                        font-size: 14px;
                    }
                 }

            `}</style>
        </Fragment>
    );
};

export default WorkshopCard;
